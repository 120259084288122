import { Router } from '@angular/router';
import { Settings } from '../core/config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/config/settings.service";
import * as i2 from "@angular/router";
var DisableRouteGuard = /** @class */ (function () {
    function DisableRouteGuard(settings, router) {
        this.settings = settings;
        this.router = router;
    }
    DisableRouteGuard.prototype.canActivate = function (route, state) {
        if (!this.settings.get('registration.disable')) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    };
    DisableRouteGuard.ngInjectableDef = i0.defineInjectable({ factory: function DisableRouteGuard_Factory() { return new DisableRouteGuard(i0.inject(i1.Settings), i0.inject(i2.Router)); }, token: DisableRouteGuard, providedIn: "root" });
    return DisableRouteGuard;
}());
export { DisableRouteGuard };
