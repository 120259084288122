<div class="img-container">
    <img [src]="wpImages.getDefault('album')" [lazyLoad]="wpImages.getPlaylistImage(playlist)" [scrollTarget]="scrollContainer" class="lazy-loaded">
    <playback-control-button (play)="play()" (pause)="pause()" [playing]="playing()"></playback-control-button>
</div>
<div class="footer">
    <a class="playlist-name primary-text" [routerLink]="urls.playlist(playlist)">{{playlist.name}}</a>
    <div class="creator-name secondary-text">
        <span trans>By</span>&nbsp;
        <a [routerLink]="urls.user(getCreator())" class="hover-underline">{{getCreator()['display_name']}}</a>
    </div>
</div>