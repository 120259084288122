/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./artists-links-list.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./artists-links-list.component";
import * as i5 from "../../web-player-urls.service";
var styles_ArtistsLinksListComponent = [i0.styles];
var RenderType_ArtistsLinksListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArtistsLinksListComponent, data: {} });
export { RenderType_ArtistsLinksListComponent as RenderType_ArtistsLinksListComponent };
function View_ArtistsLinksListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_ArtistsLinksListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArtistsLinksListComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.artist(_v.context.$implicit); _ck(_v, 1, 0, currVal_2); var currVal_4 = !_v.context.last; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit; _ck(_v, 4, 0, currVal_3); }); }
export function View_ArtistsLinksListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArtistsLinksListComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artists; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArtistsLinksListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "artists-links-list", [], null, null, null, View_ArtistsLinksListComponent_0, RenderType_ArtistsLinksListComponent)), i1.ɵdid(1, 49152, null, 0, i4.ArtistsLinksListComponent, [i5.WebPlayerUrls], null, null)], null, null); }
var ArtistsLinksListComponentNgFactory = i1.ɵccf("artists-links-list", i4.ArtistsLinksListComponent, View_ArtistsLinksListComponent_Host_0, { artists: "artists" }, {}, []);
export { ArtistsLinksListComponentNgFactory as ArtistsLinksListComponentNgFactory };
