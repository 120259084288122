/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lyrics-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./lyrics-modal.component";
var styles_LyricsModalComponent = [i0.styles];
var RenderType_LyricsModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LyricsModalComponent, data: {} });
export { RenderType_LyricsModalComponent as RenderType_LyricsModalComponent };
export function View_LyricsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "close-button no-style"], ["mat-dialog-close", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dialogRef.close(i1.ɵnov(_v, 1).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "lyrics"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); var currVal_4 = "close"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).ariaLabel; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _co.lyrics; _ck(_v, 10, 0, currVal_5); }); }
export function View_LyricsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lyrics-modal", [], null, null, null, View_LyricsModalComponent_0, RenderType_LyricsModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.LyricsModalComponent, [i2.MatDialogRef, [2, i2.MAT_DIALOG_DATA]], null, null)], null, null); }
var LyricsModalComponentNgFactory = i1.ɵccf("lyrics-modal", i5.LyricsModalComponent, View_LyricsModalComponent_Host_0, {}, {}, []);
export { LyricsModalComponentNgFactory as LyricsModalComponentNgFactory };
