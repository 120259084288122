/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./album-context-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../common/core/translations/translations.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../context-menu/context-menu-playlist-panel/context-menu-playlist-panel.component.ngfactory";
import * as i10 from "../../context-menu/context-menu-playlist-panel/context-menu-playlist-panel.component";
import * as i11 from "../../playlists/user-playlists.service";
import * as i12 from "../../playlists/playlists.service";
import * as i13 from "../../../../common/core/ui/context-menu/context-menu.service";
import * as i14 from "../../../../common/core/ui/dialogs/modal.service";
import * as i15 from "../../../../common/auth/current-user";
import * as i16 from "../../../../common/core/ui/toast.service";
import * as i17 from "./album-context-menu.component";
var styles_AlbumContextMenuComponent = [i0.styles];
var RenderType_AlbumContextMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AlbumContextMenuComponent, data: {} });
export { RenderType_AlbumContextMenuComponent as RenderType_AlbumContextMenuComponent };
function View_AlbumContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "menu-item"], ["routerLink", "admin/albums"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), i1.ɵdid(2, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(3, { album_id: 0 }), (_l()(), i1.ɵted(-1, null, ["Edit"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.data.item.id); var currVal_3 = "admin/albums"; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AlbumContextMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "primary-name hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "secondary-name hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(16, 0, null, null, 31, "div", [["class", "context-menu-panel primary-panel"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(18, 0, null, null, 28, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addToQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Add to Queue"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(24, 0, null, null, 6, "div", [["class", "menu-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.openPanel("playlist");
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(26, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Add to Playlist"])), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "keyboard-arrow-right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(30, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveToLibrary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Save to Your Music"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(36, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyLinkToClipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(37, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Copy Album Link"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(40, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openShareModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(41, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Share"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumContextMenuComponent_1)), i1.ɵdid(45, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(49, 0, null, null, 1, "context-menu-playlist-panel", [["class", "context-menu-panel"]], [[2, "hidden", null]], [[null, "close$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close$" === en)) {
        var pd_0 = (_co.openPanel("primary") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ContextMenuPlaylistPanelComponent_0, i9.RenderType_ContextMenuPlaylistPanelComponent)), i1.ɵdid(50, 49152, null, 0, i10.ContextMenuPlaylistPanelComponent, [i11.UserPlaylists, i12.Playlists, i13.ContextMenu, i14.Modal, i15.CurrentUser, i5.Router, i16.Toast], { tracks: [0, "tracks"] }, { close$: "close$" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.album(_co.data.item); _ck(_v, 7, 0, currVal_3); var currVal_7 = _co.urls.artist(_co.data.item.artist); _ck(_v, 11, 0, currVal_7); var currVal_12 = "keyboard-arrow-right"; _ck(_v, 30, 0, currVal_12); var currVal_13 = _co.currentUser.hasPermission("albums.update"); _ck(_v, 45, 0, currVal_13); var currVal_15 = _co.getTracks(); _ck(_v, 50, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.item.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = _co.data.item.name; _ck(_v, 8, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 11).target; var currVal_6 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_8 = _co.data.item.artist.name; _ck(_v, 12, 0, currVal_8); var currVal_9 = !_co.activePanelIs("primary"); _ck(_v, 16, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 30).inline; var currVal_11 = (((i1.ɵnov(_v, 30).color !== "primary") && (i1.ɵnov(_v, 30).color !== "accent")) && (i1.ɵnov(_v, 30).color !== "warn")); _ck(_v, 29, 0, currVal_10, currVal_11); var currVal_14 = !_co.activePanelIs("playlist"); _ck(_v, 49, 0, currVal_14); }); }
export function View_AlbumContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "album-context-menu", [["class", "context-menu"]], null, null, null, View_AlbumContextMenuComponent_0, RenderType_AlbumContextMenuComponent)), i1.ɵdid(1, 49152, null, 0, i17.AlbumContextMenuComponent, [i1.Injector], null, null)], null, null); }
var AlbumContextMenuComponentNgFactory = i1.ɵccf("album-context-menu", i17.AlbumContextMenuComponent, View_AlbumContextMenuComponent_Host_0, {}, {}, []);
export { AlbumContextMenuComponentNgFactory as AlbumContextMenuComponentNgFactory };
