/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./artist-context-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../common/core/translations/translations.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./artist-context-menu.component";
var styles_ArtistContextMenuComponent = [i0.styles];
var RenderType_ArtistContextMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArtistContextMenuComponent, data: {} });
export { RenderType_ArtistContextMenuComponent as RenderType_ArtistContextMenuComponent };
function View_ArtistContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToArtistRadio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Go to Artist Radio"]))], null, null); }
function View_ArtistContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "menu-item"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), i1.ɵdid(2, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Edit"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.editArtist(_co.data.item); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ArtistContextMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "primary-name hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(12, 0, null, null, 18, "div", [["class", "context-menu-panel primary-panel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 15, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArtistContextMenuComponent_1)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyLinkToClipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Copy Artist Link"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openShareModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Share"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArtistContextMenuComponent_2)), i1.ɵdid(28, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.artist(_co.data.item); _ck(_v, 7, 0, currVal_3); var currVal_5 = !_co.settings.get("player.hide_radio_button"); _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.currentUser.hasPermission("artists.update"); _ck(_v, 28, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.item.image_small; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = _co.data.item.name; _ck(_v, 8, 0, currVal_4); }); }
export function View_ArtistContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "artist-context-menu", [["class", "context-menu"]], null, null, null, View_ArtistContextMenuComponent_0, RenderType_ArtistContextMenuComponent)), i1.ɵdid(1, 49152, null, 0, i7.ArtistContextMenuComponent, [i1.Injector], null, null)], null, null); }
var ArtistContextMenuComponentNgFactory = i1.ɵccf("artist-context-menu", i7.ArtistContextMenuComponent, View_ArtistContextMenuComponent_Host_0, {}, {}, []);
export { ArtistContextMenuComponentNgFactory as ArtistContextMenuComponentNgFactory };
