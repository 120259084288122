import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { InfiniteScroll } from '../../common/core/ui/infinite-scroll/infinite.scroll';
var InfiniteScrollDirective = /** @class */ (function (_super) {
    tslib_1.__extends(InfiniteScrollDirective, _super);
    function InfiniteScrollDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.infiniteScrollEnabled = false;
        _this.onInfiniteScroll = new EventEmitter();
        return _this;
    }
    InfiniteScrollDirective.prototype.canLoadMore = function () {
        return this.infiniteScrollEnabled;
    };
    InfiniteScrollDirective.prototype.isLoading = function () {
        return false;
    };
    InfiniteScrollDirective.prototype.loadMoreItems = function () {
        this.onInfiniteScroll.emit();
    };
    return InfiniteScrollDirective;
}(InfiniteScroll));
export { InfiniteScrollDirective };
