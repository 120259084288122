import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FilterablePage } from "../../../filterable-page/filterable-page";
import { WebPlayerUrls } from "../../../web-player-urls.service";
var LibraryAlbumsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LibraryAlbumsComponent, _super);
    /**
     * LibraryAlbumsComponent Constructor.
     */
    function LibraryAlbumsComponent(route, urls) {
        var _this = _super.call(this, ['name', 'artist.name']) || this;
        _this.route = route;
        _this.urls = urls;
        /**
         * Current order for library albums.
         */
        _this.order = 'date added';
        return _this;
    }
    LibraryAlbumsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.albums);
        });
    };
    return LibraryAlbumsComponent;
}(FilterablePage));
export { LibraryAlbumsComponent };
