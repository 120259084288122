import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { WebPlayerUrls } from "../../web-player-urls.service";
import { FormattedDuration } from "../../player/formatted-duration.service";
import { Player } from "../../player/player.service";
import { AlbumContextMenuComponent } from "../album-context-menu/album-context-menu.component";
import { WpUtils } from "../../web-player-utils";
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
var AlbumComponent = /** @class */ (function () {
    /**
     * AlbumComponent Constructor.
     */
    function AlbumComponent(route, urls, duration, player, contextMenu) {
        this.route = route;
        this.urls = urls;
        this.duration = duration;
        this.player = player;
        this.contextMenu = contextMenu;
    }
    AlbumComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setAlbum(data.api.album);
            var total = _this.album.tracks.reduce(function (total, track2) { return total + track2.duration; }, 0);
            _this.totalDuration = _this.duration.fromMilliseconds(total);
        });
    };
    /**
     * Check if album is playing currently.
     */
    AlbumComponent.prototype.playing = function () {
        return this.player.state.playing && this.player.queue.getQueuedItem() === this.album.id;
    };
    /**
     * Play all album tracks.
     */
    AlbumComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.player.stop();
                this.player.state.buffering = true;
                this.player.overrideQueue({
                    tracks: this.album.tracks,
                    queuedItemId: this.album.id
                }).then(function () {
                    _this.player.play();
                });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Pause the album.
     */
    AlbumComponent.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Open album context menu.
     */
    AlbumComponent.prototype.openContextMenu = function (e) {
        e.stopPropagation();
        this.contextMenu.open(AlbumContextMenuComponent, e.target, { data: { item: this.album, type: 'album' } });
    };
    /**
     * Set album object on each album track.
     */
    AlbumComponent.prototype.setAlbum = function (album) {
        var simplifiedAlbum = Object.assign({}, album, { tracks: [] });
        album.tracks = WpUtils.assignAlbumToTracks(album.tracks, simplifiedAlbum);
        this.album = album;
    };
    return AlbumComponent;
}());
export { AlbumComponent };
