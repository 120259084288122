import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FilterablePage } from '../../filterable-page/filterable-page';
import { ActivatedRoute } from '@angular/router';
var TopTracksPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TopTracksPageComponent, _super);
    function TopTracksPageComponent(route) {
        var _this = _super.call(this, ['name', 'album.name', 'album.artist.name']) || this;
        _this.route = route;
        return _this;
    }
    TopTracksPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.api.tracks);
        });
    };
    return TopTracksPageComponent;
}(FilterablePage));
export { TopTracksPageComponent };
