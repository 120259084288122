import { Routes } from '@angular/router';
import { PopularAlbumsComponent } from './albums/popular-albums/popular-albums.component';
import { WebPlayerComponent } from './web-player.component';
import { PopularGenresComponent } from './genres/popular-genres/popular-genres.component';
import { AlbumComponent } from './albums/album/album.component';
import { AlbumResolver } from './albums/album/album-resolver.service';
import { PopularAlbumsResolver } from './albums/popular-albums/popular-albums-resolver.service';
import { PopularGenresResolver } from './genres/popular-genres/popular-genres-resolver.service';
import { LibraryTracksComponent } from './users/user-library/library-tracks/library-tracks.component';
import { LibraryTracksResolver } from './users/user-library/library-tracks/library-tracks-resolver.service';
import { LibraryAlbumsComponent } from './users/user-library/library-albums/library-albums.component';
import { LibraryAlbumsResolver } from './users/user-library/library-albums/library-albums-resolver.service';
import { LibraryArtistsComponent } from './users/user-library/library-artists/library-artists.component';
import { LibraryArtistsResolver } from './users/user-library/library-artists/library-artists-resolver.service';
import { ArtistComponent } from './artists/artist/artist.component';
import { ArtistResolver } from './artists/artist/artist-resolver.service';
import { GenreComponent } from './genres/genre/genre.component';
import { GenreArtistsResolver } from './genres/genre/genre-artists-resolver.service';
import { PlaylistComponent } from './playlists/playlist/playlist.component';
import { PlaylistResolver } from './playlists/playlist/playlist-resolver.service';
import { SearchComponent } from './search/search/search.component';
import { SearchResolver } from './search/search/search-resolver.service';
import { SearchTabValidGuard } from './search/search/search-tab-valid.guard';
import { TrackPageComponent } from './tracks/track-page/track-page.component';
import { TrackPageResolver } from './tracks/track-page/track-page-resolver.service';
import { UserProfilePageComponent } from './users/user-profile-page/user-profile-page.component';
import { UserProfilePageResolver } from './users/user-profile-page/user-profile-page-resolver.service';
import { NewReleasesPageComponent } from './albums/new-releases-page/new-releases-page.component';
import { NewReleasesPageResolver } from './albums/new-releases-page/new-releases-page.resolver.service';
import { TopTracksPageComponent } from './tracks/top-tracks-page/top-tracks-page.component';
import { TopTracksPageResolver } from './tracks/top-tracks-page/top-tracks-page-resolver.service';
import { RadioPageComponent } from './radio-page/radio-page.component';
import { RadioPageResolver } from './radio-page/radio-page-resolver.service';
import { UserLibraryComponent } from './users/user-library/user-library.component';
import { LibraryPlaylistsComponent } from './users/user-library/library-playlists/library-playlists.component';
import { LibraryPlaylistsResolver } from './users/user-library/library-playlists/library-playlists-resolver.service';
import { EmptyRouteComponent } from 'common/core/ui/empty-route/empty-route.component';
import { CheckPermissionsGuard } from 'common/guards/check-permissions-guard.service';
import { AuthGuard } from 'common/guards/auth-guard.service';
import { AccountSettingsResolve } from 'common/account-settings/account-settings-resolve.service';
import { AccountSettingsComponent } from 'common/account-settings/account-settings.component';
import { CustomPageComponent } from 'common/core/pages/custom-page/custom-page.component';
import { NotFoundPageComponent } from 'common/core/pages/not-found-page/not-found-page.component';
var ɵ0 = { name: 'parent-home-route' }, ɵ1 = { name: 'home' }, ɵ2 = { name: 'popular-albums' }, ɵ3 = { name: 'new-releases' }, ɵ4 = { name: 'top-50' }, ɵ5 = { name: 'popular-genres' }, ɵ6 = { name: 'album' }, ɵ7 = { name: 'genre' }, ɵ8 = { name: 'playlist' }, ɵ9 = { name: 'playlist' }, ɵ10 = { name: 'track' }, ɵ11 = { name: 'track' }, ɵ12 = { name: 'user' }, ɵ13 = { name: 'user' }, ɵ14 = { name: 'search' }, ɵ15 = { name: 'search' }, ɵ16 = { name: 'search' }, ɵ17 = { name: 'artist', willSetSeo: true }, ɵ18 = { name: 'artist', willSetSeo: true }, ɵ19 = { type: 'artist', name: 'radio' }, ɵ20 = { type: 'track', name: 'radio' }, ɵ21 = { name: 'library.tracks', title: 'Your Tracks' }, ɵ22 = { name: 'library.albums', title: 'Your Albums' }, ɵ23 = { name: 'library.artists', title: 'Your Artists' }, ɵ24 = { name: 'library.playlists', title: 'Your Playlists' }, ɵ25 = { name: 'account-settings' }, ɵ26 = { permissions: ['pages.view'] };
var routes = [
    { path: '', component: WebPlayerComponent, canActivateChild: [CheckPermissionsGuard], data: ɵ0, children: [
            { path: '', component: PopularGenresComponent, resolve: { api: PopularGenresResolver }, data: ɵ1 },
            {
                path: 'popular-albums',
                component: PopularAlbumsComponent,
                resolve: { api: PopularAlbumsResolver },
                data: ɵ2
            },
            { path: 'new-releases', component: NewReleasesPageComponent, resolve: { api: NewReleasesPageResolver }, data: ɵ3 },
            { path: 'top-50', component: TopTracksPageComponent, resolve: { api: TopTracksPageResolver }, data: ɵ4 },
            { path: 'popular-genres', component: PopularGenresComponent, resolve: { api: PopularGenresResolver }, data: ɵ5 },
            { path: 'album/:id/:artist/:album', component: AlbumComponent, resolve: { api: AlbumResolver }, data: ɵ6 },
            { path: 'genre/:name', component: GenreComponent, resolve: { api: GenreArtistsResolver }, data: ɵ7 },
            { path: 'playlists/:id', component: PlaylistComponent, resolve: { api: PlaylistResolver }, data: ɵ8 },
            { path: 'playlists/:id/:name', component: PlaylistComponent, resolve: { api: PlaylistResolver }, data: ɵ9 },
            { path: 'track/:id', component: TrackPageComponent, resolve: { api: TrackPageResolver }, data: ɵ10 },
            { path: 'track/:id/:name', component: TrackPageComponent, resolve: { api: TrackPageResolver }, data: ɵ11 },
            { path: 'user/:id', component: UserProfilePageComponent, resolve: { user: UserProfilePageResolver }, data: ɵ12, children: [
                    { path: 'playlists', component: EmptyRouteComponent },
                    { path: 'following', component: EmptyRouteComponent },
                    { path: 'followers', component: EmptyRouteComponent },
                ] },
            {
                path: 'user/:id/:name',
                component: UserProfilePageComponent,
                resolve: { api: UserProfilePageResolver },
                data: ɵ13,
                children: [
                    { path: 'playlists', component: EmptyRouteComponent },
                    { path: 'following', component: EmptyRouteComponent },
                    { path: 'followers', component: EmptyRouteComponent },
                ]
            },
            { path: 'search', component: SearchComponent, data: ɵ14 },
            { path: 'search/:query', component: SearchComponent, resolve: { results: SearchResolver }, data: ɵ15 },
            { path: 'search/:query/:tab', component: SearchComponent, resolve: { results: SearchResolver }, canActivate: [SearchTabValidGuard], data: ɵ16 },
            { path: 'artist/:name', component: ArtistComponent, resolve: { artist: ArtistResolver }, data: ɵ17, children: [
                    { path: 'similar', component: EmptyRouteComponent },
                    { path: 'about', component: EmptyRouteComponent },
                ] },
            { path: 'artist/:id/:name', component: ArtistComponent, resolve: { artist: ArtistResolver }, data: ɵ18, children: [
                    { path: 'similar', component: EmptyRouteComponent },
                    { path: 'about', component: EmptyRouteComponent },
                ] },
            { path: 'radio/artist/:id/:name', component: RadioPageComponent, resolve: { radio: RadioPageResolver }, data: ɵ19 },
            { path: 'radio/track/:id/:name', component: RadioPageComponent, resolve: { radio: RadioPageResolver }, data: ɵ20 },
            { path: 'library', component: UserLibraryComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
                    { path: '', redirectTo: 'songs', pathMatch: 'full' },
                    { path: 'songs', component: LibraryTracksComponent, resolve: { pagination: LibraryTracksResolver }, data: ɵ21 },
                    { path: 'albums', component: LibraryAlbumsComponent, resolve: { albums: LibraryAlbumsResolver }, data: ɵ22 },
                    { path: 'artists', component: LibraryArtistsComponent, resolve: { artists: LibraryArtistsResolver }, data: ɵ23 },
                    { path: 'playlists', component: LibraryPlaylistsComponent, resolve: { playlists: LibraryPlaylistsResolver }, data: ɵ24 },
                ] },
            { path: 'account-settings', redirectTo: 'account/settings' },
            { path: 'account/settings', component: AccountSettingsComponent, resolve: { resolves: AccountSettingsResolve }, canActivate: [AuthGuard], data: ɵ25 },
            {
                path: 'pages/:id/:slug',
                component: CustomPageComponent,
                data: ɵ26
            },
            {
                path: '**',
                component: NotFoundPageComponent,
            }
        ] }
];
var WebPlayerRoutingModule = /** @class */ (function () {
    function WebPlayerRoutingModule() {
    }
    return WebPlayerRoutingModule;
}());
export { WebPlayerRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26 };
