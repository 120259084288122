/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-host.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ad-host.component";
import * as i3 from "../../config/settings.service";
import * as i4 from "../../../auth/current-user";
import * as i5 from "../../utils/lazy-loader.service";
var styles_AdHostComponent = [i0.styles];
var RenderType_AdHostComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AdHostComponent, data: {} });
export { RenderType_AdHostComponent as RenderType_AdHostComponent };
export function View_AdHostComponent_0(_l) { return i1.ɵvid(2, [], null, null); }
export function View_AdHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ad-host", [], [[8, "id", 0]], null, null, View_AdHostComponent_0, RenderType_AdHostComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdHostComponent, [i1.ElementRef, i1.Renderer2, i3.Settings, i4.CurrentUser, i5.LazyLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).randomId; _ck(_v, 0, 0, currVal_0); }); }
var AdHostComponentNgFactory = i1.ɵccf("ad-host", i2.AdHostComponent, View_AdHostComponent_Host_0, { slot: "slot" }, {}, []);
export { AdHostComponentNgFactory as AdHostComponentNgFactory };
