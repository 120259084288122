import { NgZone } from '@angular/core';
import { UploadQueueItem } from './upload-queue-item';
import { BehaviorSubject, concat } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UploadEventTypes } from '../utils/upload-progress-event';
import { UploadsApiService } from '../uploads-api.service';
import { DefaultUploadValidator } from '../validation/default-upload-validator';
import * as i0 from "@angular/core";
import * as i1 from "../uploads-api.service";
import * as i2 from "../validation/default-upload-validator";
var UploadQueueService = /** @class */ (function () {
    function UploadQueueService(api, zone, defaultValidator) {
        this.api = api;
        this.zone = zone;
        this.defaultValidator = defaultValidator;
        this.uploads$ = new BehaviorSubject([]);
        this.totalProgress$ = new BehaviorSubject(0);
    }
    UploadQueueService.prototype.getAll = function () {
        return this.uploads$.asObservable();
    };
    UploadQueueService.prototype.getAllCompleted = function () {
        return this.uploads$.value
            .filter(function (queueItem) { return queueItem.completed; })
            .map(function (queueItem) { return queueItem.fileEntry; });
    };
    UploadQueueService.prototype.getPendingCount = function () {
        return this.uploads$.pipe(map(function (uploads) {
            return uploads.filter(function (upload) { return !upload.completed; }).length;
        }));
    };
    UploadQueueService.prototype.getCompletedCount = function () {
        return this.uploads$.pipe(map(function (uploads) {
            return uploads.filter(function (upload) { return upload.completed; }).length;
        }));
    };
    UploadQueueService.prototype.updateTotalProgress = function () {
        var progress = this.uploads$.value.map(function (upload) { return upload.meta$.value.progress || 0; });
        this.totalProgress$.next(progress.reduce(function (p, c) { return p + c; }, 0) / progress.length);
    };
    UploadQueueService.prototype.totalProgress = function () {
        return this.totalProgress$.asObservable();
    };
    UploadQueueService.prototype.start = function (files, config) {
        var _this = this;
        if (config === void 0) { config = {}; }
        var uploads = this.transformUploads(files, config.validator || this.defaultValidator);
        this.uploads$.next(this.uploads$.value.concat(uploads));
        var requests = uploads
            .filter(function (upload) { return !upload.hasError; })
            .map(function (upload, key) {
            return _this.api.upload(files[key], config).pipe(takeUntil(upload.canceled$), tap(function (response) { return _this.handleUploadEvent(response, upload); }, function (response) { return _this.handleUploadFailure(response, upload); }));
        });
        return concat.apply(void 0, requests).pipe(filter(function (e) { return e.name === UploadEventTypes.COMPLETED; }), map(function (e) { return e.fileEntry; }));
    };
    UploadQueueService.prototype.updateProgress = function (id, e) {
        var queueItem = this.find(id);
        if (!queueItem)
            return;
        queueItem.update({
            eta: e.eta,
            speed: e.speed,
            progress: e.progress,
            totalBytes: e.totalBytes,
            completedBytes: e.completedBytes,
        });
        this.updateTotalProgress();
    };
    UploadQueueService.prototype.completeUpload = function (id, fileEntry) {
        var queueItem = this.find(id);
        if (!queueItem)
            return;
        queueItem.fileEntry = fileEntry;
        queueItem.complete();
    };
    UploadQueueService.prototype.errorUpload = function (id, message) {
        if (message === void 0) { message = ''; }
        this.find(id).addError(message);
    };
    UploadQueueService.prototype.reset = function () {
        this.uploads$.next([]);
    };
    UploadQueueService.prototype.remove = function (id) {
        var i = this.uploads$.value.findIndex(function (u) { return u.id === id; });
        this.uploads$.value.splice(i, 1);
        this.uploads$.next(this.uploads$.value);
    };
    UploadQueueService.prototype.find = function (id) {
        return this.uploads$.value.find(function (u) { return u.id === id; });
    };
    /**
     * Transform specified files into upload queue items.
     */
    UploadQueueService.prototype.transformUploads = function (files, validator) {
        var _this = this;
        return files.map(function (file) {
            var activeUpload = new UploadQueueItem(file);
            // validate upload
            if (validator) {
                var result = validator.validate(file);
                if (result.failed)
                    activeUpload.addError(result.errorMessage);
            }
            // remove upload, if it is canceled by user
            activeUpload.canceled$.subscribe(function () {
                _this.remove(activeUpload.id);
            });
            return activeUpload;
        });
    };
    UploadQueueService.prototype.handleUploadEvent = function (event, upload) {
        var _this = this;
        if (event.name === UploadEventTypes.PROGRESS) {
            this.zone.run(function () {
                _this.updateProgress(upload.id, event);
            });
        }
        else if (event.name === UploadEventTypes.COMPLETED) {
            this.zone.run(function () {
                _this.completeUpload(upload.id, event.fileEntry);
            });
        }
    };
    UploadQueueService.prototype.handleUploadFailure = function (response, upload) {
        var msg = response.messages ? response.messages.file : '';
        this.errorUpload(upload.id, msg);
    };
    UploadQueueService.ngInjectableDef = i0.defineInjectable({ factory: function UploadQueueService_Factory() { return new UploadQueueService(i0.inject(i1.UploadsApiService), i0.inject(i0.NgZone), i0.inject(i2.DefaultUploadValidator)); }, token: UploadQueueService, providedIn: "root" });
    return UploadQueueService;
}());
export { UploadQueueService };
