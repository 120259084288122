import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebPlayerUrls } from '../../web-player-urls.service';
import { Player } from '../../player/player.service';
import { AlbumContextMenuComponent } from '../../albums/album-context-menu/album-context-menu.component';
import { ArtistContextMenuComponent } from '../artist-context-menu/artist-context-menu.component';
import { WebPlayerState } from '../../web-player-state.service';
import { Settings } from 'common/core/config/settings.service';
import { filter } from 'rxjs/operators';
import { WebPlayerImagesService } from '../../web-player-images.service';
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
var ArtistComponent = /** @class */ (function () {
    /**
     * ArtistComponent Constructor.
     */
    function ArtistComponent(route, router, urls, player, contextMenu, state, settings, wpImages) {
        this.route = route;
        this.router = router;
        this.urls = urls;
        this.player = player;
        this.contextMenu = contextMenu;
        this.state = state;
        this.settings = settings;
        this.wpImages = wpImages;
        /**
         * Active component subscriptions.
         */
        this.subscriptions = [];
        /**
         * Current layout of artist albums in the view.
         */
        this.albumsLayout = 'list';
        /**
         * Whether albums layout toggle button should be visible.
         */
        this.albumsLayoutShouldBeToggleable = true;
        /**
         * Currently active tab.
         */
        this.activeTab = 'overview';
        /**
         * number of popular tracks that should be displayed
         */
        this.popularTracksCount = 5;
    }
    ArtistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.popularTracksCount = 5;
            _this.artist = data.artist;
        });
        this.setActiveTab(this.router.url);
        this.bindToRouterEvents();
        this.setAlbumsLayout();
    };
    ArtistComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.artist.destroy();
        this.subscriptions = [];
    };
    /**
     * Play all artist tracks from specified track.
     */
    ArtistComponent.prototype.playFrom = function (track) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tracks, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tracks = this.artist.getTracks(), index = tracks.findIndex(function (curr) { return curr === track; });
                        this.player.handleContinuousPlayback = false;
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: tracks.slice(index), queuedItemId: this.artist.get().id })];
                    case 1:
                        _a.sent();
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Toggle number of popular tracks that
     * should be displayed between 5 and 20.
     */
    ArtistComponent.prototype.togglePopularTracksCount = function () {
        this.popularTracksCount = this.popularTracksCount === 5 ? 20 : 5;
    };
    /**
     * Show context menu for specified track.
     */
    ArtistComponent.prototype.showAlbumContextMenu = function (album, e) {
        e.stopPropagation();
        this.contextMenu.open(AlbumContextMenuComponent, e.target, { overlayY: 'center', data: { item: album, type: 'album' } });
    };
    /**
     * Open artist context menu.
     */
    ArtistComponent.prototype.showArtistContextMenu = function (e) {
        e.stopPropagation();
        this.contextMenu.open(ArtistContextMenuComponent, e.target, { data: { item: this.artist.get(), type: 'artist' }, originX: 'center', overlayX: 'center' });
    };
    /**
     * Toggle albums layout between grid and list.
     */
    ArtistComponent.prototype.toggleAlbumsLayout = function () {
        if (this.albumsLayout === 'grid') {
            this.albumsLayout = 'list';
        }
        else {
            this.albumsLayout = 'grid';
        }
    };
    /**
     * Bind to router state change events.
     */
    ArtistComponent.prototype.bindToRouterEvents = function () {
        var _this = this;
        var sub = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.setActiveTab(event.url);
        });
        this.subscriptions.push(sub);
    };
    /**
     * Set currently active tab based on specified url.
     */
    ArtistComponent.prototype.setActiveTab = function (url) {
        var tab = url.split('/').pop();
        switch (tab) {
            case 'about':
                this.activeTab = 'about';
                break;
            case 'similar':
                this.activeTab = 'similar';
                break;
            default:
                this.activeTab = 'overview';
        }
    };
    /**
     * Set albums layout based on current artist provider.
     */
    ArtistComponent.prototype.setAlbumsLayout = function () {
        if (this.settings.get('artist_provider') === 'Discogs') {
            this.albumsLayout = 'grid';
            this.albumsLayoutShouldBeToggleable = false;
        }
        else {
            this.albumsLayout = 'list';
            this.albumsLayoutShouldBeToggleable = true;
        }
    };
    return ArtistComponent;
}());
export { ArtistComponent };
