import * as tslib_1 from "tslib";
import { PlayerState } from '../player-state.service';
import { NgZone } from '@angular/core';
import { PlayerQueue } from '../player-queue.service';
import { WebPlayerImagesService } from '../../web-player-images.service';
var Html5Strategy = /** @class */ (function () {
    /**
     * Html5Strategy Constructor.
     */
    function Html5Strategy(state, zone, queue, wpImages) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.wpImages = wpImages;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         * Number between 1 and 100.
         */
        this.pendingVolume = null;
    }
    /**
     * Start playback.
     */
    Html5Strategy.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cueTrack(this.queue.getCurrent())];
                    case 1:
                        _a.sent();
                        this.html5.play();
                        this.state.playing = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause playback.
     */
    Html5Strategy.prototype.pause = function () {
        this.html5.pause();
        this.state.playing = false;
    };
    /**
     * Stop playback.
     */
    Html5Strategy.prototype.stop = function () {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    };
    /**
     * Seek to specified time in track.
     */
    Html5Strategy.prototype.seekTo = function (time) {
        this.html5.currentTime = time;
    };
    /**
     * Get loaded track duration in seconds.
     */
    Html5Strategy.prototype.getDuration = function () {
        if (!this.html5.seekable.length)
            return 0;
        return this.html5.seekable.end(0);
    };
    /**
     * Get elapsed time in seconds since the track started playing
     */
    Html5Strategy.prototype.getCurrentTime = function () {
        return this.html5.currentTime;
    };
    /**
     * Set html5 player volume to float between 0 and 1.
     */
    Html5Strategy.prototype.setVolume = function (number) {
        if (!this.html5) {
            this.pendingVolume = number;
        }
        else {
            this.html5.volume = number / 100;
        }
    };
    /**
     * Mute the player.
     */
    Html5Strategy.prototype.mute = function () {
        this.html5.muted = true;
    };
    /**
     * Unmute the player.
     */
    Html5Strategy.prototype.unMute = function () {
        this.html5.muted = false;
    };
    /**
     * Get track that is currently cued for playback.
     */
    Html5Strategy.prototype.getCuedTrack = function () {
        return this.cuedTrack;
    };
    /**
     * Check if youtube player is ready.
     */
    Html5Strategy.prototype.ready = function () {
        return this.bootstrapped;
    };
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    Html5Strategy.prototype.cueTrack = function (track) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.cueing === track || this.cuedTrack === track)
                    return [2 /*return*/];
                this.cueing = track;
                this.state.buffering = true;
                this.bootstrap();
                this.html5.src = track.url;
                this.html5.poster = this.wpImages.getTrackImage(track);
                this.cuedTrack = track;
                this.cueing = null;
                return [2 /*return*/, new Promise(function (resolve) { return resolve(); })];
            });
        });
    };
    /**
     * Destroy html5 playback strategy.
     */
    Html5Strategy.prototype.destroy = function () {
        this.html5 && this.html5.remove();
        this.html5 = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    };
    /**
     * Bootstrap html5 player.
     */
    Html5Strategy.prototype.bootstrap = function () {
        if (this.bootstrapped)
            return;
        this.html5 = document.createElement('video');
        this.html5.setAttribute('playsinline', 'true');
        this.html5.id = 'html5-player';
        document.querySelector('.html5-player').appendChild(this.html5);
        this.handlePlayerReadyEvent();
        this.handlePlayerStateChangeEvents();
        this.bootstrapped = true;
    };
    /**
     * Handle html5 playback state change events.
     */
    Html5Strategy.prototype.handlePlayerStateChangeEvents = function () {
        var _this = this;
        this.html5.addEventListener('ended', function () {
            _this.state.firePlaybackEnded();
            _this.setState('playing', false);
        });
        this.html5.addEventListener('playing', function (e) {
            _this.setState('playing', true);
        });
        this.html5.addEventListener('pause', function () {
            _this.setState('playing', false);
        });
        this.html5.addEventListener('error', function () {
            _this.cuedTrack = null;
            _this.setState('playing', false);
            _this.state.firePlaybackEnded();
        });
    };
    /**
     * Set specified player state.
     */
    Html5Strategy.prototype.setState = function (name, value) {
        var _this = this;
        this.zone.run(function () { return _this.state[name] = value; });
    };
    /**
     * Handle html5 player ready event.
     */
    Html5Strategy.prototype.handlePlayerReadyEvent = function (resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    };
    return Html5Strategy;
}());
export { Html5Strategy };
