import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
var BrowserEvents = /** @class */ (function () {
    function BrowserEvents() {
        /**
         * Keyboard key codes map.
         */
        this.keyCodes = {
            enter: 13,
            space: 32,
            escape: 27,
            delete: 46,
            arrowUp: 38,
            arrowRight: 39,
            arrowDown: 40,
            arrowLeft: 37,
            letters: {
                s: 83,
                n: 78,
                a: 65,
                t: 84,
                b: 66,
                c: 67,
                o: 79,
                p: 80,
                r: 82,
                f: 70,
            },
        };
    }
    /**
     * Create observables for document events.
     */
    BrowserEvents.prototype.subscribeToEvents = function (el) {
        // document click event
        this.globalClick$ = fromEvent(el, 'click');
        // document keyDown event
        this.globalKeyDown$ = fromEvent(document, 'keydown')
            .pipe(filter(function () { return !BrowserEvents.userIsTyping(); }));
    };
    /**
     * Check if an input or textarea element is currently focused.
     */
    BrowserEvents.userIsTyping = function () {
        var inputs = ['input', 'textarea'], tagName = document.activeElement.tagName;
        if (!tagName)
            return true;
        return inputs.indexOf(tagName.toLowerCase()) > -1;
    };
    BrowserEvents.ngInjectableDef = i0.defineInjectable({ factory: function BrowserEvents_Factory() { return new BrowserEvents(); }, token: BrowserEvents, providedIn: "root" });
    return BrowserEvents;
}());
export { BrowserEvents };
