import { NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentUser } from './current-user';
import { Toast } from '../core/ui/toast.service';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
import * as i2 from "./current-user";
import * as i3 from "@angular/router";
import * as i4 from "../core/ui/toast.service";
import * as i5 from "../core/config/settings.service";
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, currentUser, router, route, toast, zone, config) {
        this.httpClient = httpClient;
        this.currentUser = currentUser;
        this.router = router;
        this.route = route;
        this.toast = toast;
        this.zone = zone;
        this.config = config;
    }
    /**
     * Log user in with specified credentials.
     */
    AuthService.prototype.login = function (credentials) {
        return this.httpClient.post('auth/login', credentials);
    };
    /**
     * Register a new user.
     */
    AuthService.prototype.register = function (credentials) {
        return this.httpClient.post('auth/register', credentials);
    };
    /**
     * Log current user out.
     */
    AuthService.prototype.logOut = function () {
        var _this = this;
        this.httpClient.post('auth/logout').subscribe(function () {
            _this.currentUser.clear();
            _this.router.navigate(['/login']);
        });
    };
    /**
     * Send password reset link to user via email.
     */
    AuthService.prototype.sendPasswordResetLink = function (credentials) {
        return this.httpClient.post('auth/password/email', credentials);
    };
    /**
     * Reset user password.
     */
    AuthService.prototype.resetPassword = function (credentials) {
        return this.httpClient.post('auth/password/reset', credentials);
    };
    /**
     * Get URI user should be redirect to after login.
     */
    AuthService.prototype.getRedirectUri = function () {
        if (this.currentUser.redirectUri) {
            var redirectUri = this.currentUser.redirectUri;
            this.currentUser.redirectUri = null;
            return redirectUri;
        }
        else if (this.currentUser.isAdmin()) {
            return this.config.get('vebto.auth.adminRedirectUri');
        }
        else {
            return this.config.get('vebto.auth.redirectUri');
        }
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.AppHttpClient), i0.inject(i2.CurrentUser), i0.inject(i3.Router), i0.inject(i3.ActivatedRoute), i0.inject(i4.Toast), i0.inject(i0.NgZone), i0.inject(i5.Settings)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
