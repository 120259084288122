import * as tslib_1 from "tslib";
import { Player } from '../player/player.service';
import { Playlists } from './playlists.service';
import { MediaItem } from '../media-item.service';
import { Settings } from 'common/core/config/settings.service';
import { WebPlayerImagesService } from '../web-player-images.service';
var PlaylistService = /** @class */ (function (_super) {
    tslib_1.__extends(PlaylistService, _super);
    function PlaylistService(playlists, player, settings, images) {
        var _this = _super.call(this, player) || this;
        _this.playlists = playlists;
        _this.player = player;
        _this.settings = settings;
        _this.images = images;
        return _this;
    }
    PlaylistService.prototype.init = function (id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.bindToPlaylistEvents();
        return _super.prototype.init.call(this, id, params).then(function (data) {
            _this.totalDuration = data.totalDuration;
            _this.tracksCount = data.playlist.tracks_count;
            return data;
        });
    };
    /**
     * Get playlist tracks for player queue.
     */
    PlaylistService.prototype.getTracks = function (paginatedData) {
        return paginatedData || this.paginatedData;
    };
    /**
     * Get playlist creator.
     */
    PlaylistService.prototype.getCreator = function () {
        return this.item.editors[0];
    };
    /**
     * Get playlist image or first album image.
     */
    PlaylistService.prototype.getImage = function () {
        if (this.item.image)
            return this.item.image;
        if (this.getTracks().length)
            return this.getTracks()[0].album.image;
        return this.images.getDefault('artist');
    };
    /**
     * Load playlist from backend.
     */
    PlaylistService.prototype.loadItem = function (id) {
        var _this = this;
        return this.playlists.get(id).toPromise().then(function (response) {
            _this.setItem(response.playlist, response.tracks);
            return response;
        });
    };
    /**
     * Load next playlist tracks page.
     */
    PlaylistService.prototype.loadNextPage = function (id, page) {
        return this.playlists.loadMoreTracks(id, page);
    };
    /**
     * Add or remove tracks from playlist when events are fired.
     */
    PlaylistService.prototype.bindToPlaylistEvents = function () {
        var _this = this;
        this.unsubscribe();
        // add tracks
        var sub1 = this.playlists.addedTracks$.subscribe(function (data) {
            if (data.id !== _this.item.id)
                return;
            _this.paginatedData = data.tracks.concat(_this.paginatedData);
        });
        // remove tracks
        var sub2 = this.playlists.removedTracks$.subscribe(function (data) {
            if (data.id !== _this.item.id)
                return;
            data.tracks.forEach(function (track) {
                var i = _this.paginatedData.findIndex(function (curr) { return curr.id === track.id; });
                _this.paginatedData.splice(i, 1);
            });
        });
        // update playlist
        var sub3 = this.playlists.updated$.subscribe(function (playlist) {
            _this.item = playlist;
        });
        this.subscriptions = this.subscriptions.concat([sub1, sub2, sub3]);
    };
    return PlaylistService;
}(MediaItem));
export { PlaylistService };
