/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./empty-route.component";
var styles_EmptyRouteComponent = [];
var RenderType_EmptyRouteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmptyRouteComponent, data: {} });
export { RenderType_EmptyRouteComponent as RenderType_EmptyRouteComponent };
export function View_EmptyRouteComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_EmptyRouteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "empty-route", [], null, null, null, View_EmptyRouteComponent_0, RenderType_EmptyRouteComponent)), i0.ɵdid(1, 49152, null, 0, i1.EmptyRouteComponent, [], null, null)], null, null); }
var EmptyRouteComponentNgFactory = i0.ɵccf("empty-route", i1.EmptyRouteComponent, View_EmptyRouteComponent_Host_0, {}, {}, []);
export { EmptyRouteComponentNgFactory as EmptyRouteComponentNgFactory };
