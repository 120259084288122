<div class="header">
    <img [src]="data.item.image">
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.album(data.item)">{{data.item.name}}</a>
        <a class="secondary-name hover-underline" [routerLink]="urls.artist(data.item.artist)">{{data.item.artist.name}}</a>
    </div>
</div>

<div class="context-menu-panel primary-panel" [class.hidden]="!activePanelIs('primary')">
    <div class="panel-body">
        <div class="menu-item" (click)="addToQueue()" trans>Add to Queue</div>
        <div class="menu-item" (click)="openPanel('playlist'); $event.stopPropagation()"><span trans>Add to Playlist</span> <mat-icon svgIcon="keyboard-arrow-right"></mat-icon></div>
        <div class="menu-item" (click)="saveToLibrary()" trans>Save to Your Music</div>
        <div class="menu-item" (click)="copyLinkToClipboard()" trans>Copy Album Link</div>
        <div class="menu-item" (click)="openShareModal()" trans>Share</div>
        <a class="menu-item"  routerLink="admin/albums" [queryParams]="{album_id: data.item.id}" *ngIf="currentUser.hasPermission('albums.update')" trans>Edit</a>
    </div>
</div>

<context-menu-playlist-panel class="context-menu-panel" [class.hidden]="!activePanelIs('playlist')" [tracks]="getTracks()" (close$)="openPanel('primary')"></context-menu-playlist-panel>