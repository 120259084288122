import * as tslib_1 from "tslib";
import { WebPlayerUrls } from '../../web-player-urls.service';
import { Album } from '../../../models/Album';
import { Albums } from '../albums.service';
import { Player } from '../../player/player.service';
import { WpUtils } from '../../web-player-utils';
import { Toast } from 'common/core/ui/toast.service';
import { WebPlayerImagesService } from '../../web-player-images.service';
var AlbumItemComponent = /** @class */ (function () {
    /**
     * AlbumItemComponent Constructor
     */
    function AlbumItemComponent(urls, albums, player, wpImages, toast) {
        this.urls = urls;
        this.albums = albums;
        this.player = player;
        this.wpImages = wpImages;
        this.toast = toast;
    }
    /**
     * Check if album is playing currently.
     */
    AlbumItemComponent.prototype.playing = function () {
        return this.player.state.playing && this.player.queue.getQueuedItem() === this.album.id;
    };
    /**
     * Play all album tracks.
     */
    AlbumItemComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, tracks;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.player.stop();
                        this.player.state.buffering = true;
                        if (!(!this.album.tracks || !this.album.tracks.length)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.albums.get(this.album.id).toPromise()];
                    case 1:
                        _a.album = (_b.sent()).album;
                        _b.label = 2;
                    case 2:
                        tracks = WpUtils.assignAlbumToTracks(this.album.tracks, this.album);
                        // if could not fetch any tracks for album, bail
                        if (!tracks.length) {
                            this.toast.open('This album has no songs yet.');
                            return [2 /*return*/, this.player.state.buffering = false];
                        }
                        this.player.overrideQueue({
                            tracks: tracks,
                            queuedItemId: this.album.id
                        }).then(function () {
                            _this.player.play();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the album.
     */
    AlbumItemComponent.prototype.pause = function () {
        this.player.pause();
    };
    return AlbumItemComponent;
}());
export { AlbumItemComponent };
