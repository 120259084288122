import { Routes } from '@angular/router';
import { CustomPageComponent } from './custom-page/custom-page.component';
import { NOT_FOUND_ROUTES } from '../wildcard-routing.module';
var ɵ0 = { permissions: ['pages.view'] };
var routes = [
    {
        path: 'pages/:id/:slug',
        component: CustomPageComponent,
        data: ɵ0
    }
].concat(NOT_FOUND_ROUTES);
var PagesRoutingModule = /** @class */ (function () {
    function PagesRoutingModule() {
    }
    return PagesRoutingModule;
}());
export { PagesRoutingModule };
var PagesModule = /** @class */ (function () {
    function PagesModule() {
    }
    return PagesModule;
}());
export { PagesModule };
export { ɵ0 };
