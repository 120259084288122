import * as tslib_1 from "tslib";
import { UploadValidator } from '../../common/uploads/validation/upload-validator';
import { FileSizeValidation } from '../../common/uploads/validation/validations/file-size-validation';
import { convertToBytes } from '../../common/core/utils/convertToBytes';
import { FileTypeValidation } from '../../common/uploads/validation/validations/file-type-validation';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
import * as i2 from "../../common/core/ui/toast.service";
import * as i3 from "../../common/core/translations/translations.service";
var ImageUploadValidator = /** @class */ (function (_super) {
    tslib_1.__extends(ImageUploadValidator, _super);
    function ImageUploadValidator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DEFAULT_MAX_SIZE_MB = 2;
        _this.showToast = true;
        return _this;
    }
    ImageUploadValidator.prototype.initValidations = function () {
        var _a;
        var validations = [
            new FileSizeValidation({ maxSize: convertToBytes(this.DEFAULT_MAX_SIZE_MB, 'MB') }, this.i18n),
            new FileTypeValidation({ types: ['image'] }, this.i18n),
        ];
        (_a = this.validations).push.apply(_a, validations);
    };
    ImageUploadValidator.ngInjectableDef = i0.defineInjectable({ factory: function ImageUploadValidator_Factory() { return new ImageUploadValidator(i0.inject(i1.Settings), i0.inject(i2.Toast), i0.inject(i3.Translations)); }, token: ImageUploadValidator, providedIn: "root" });
    return ImageUploadValidator;
}(UploadValidator));
export { ImageUploadValidator };
