<div class="scroll-wrapper" customScrollbar [class.full-height]="videoIsHidden">
    <div class="queue-item" *ngFor="let track of queue.getAll(); index as index;" [class.active]="trackIsPlaying(track)" [contextMenu]="{item: track, type: 'track'}">
        <div class="img-container" *ngIf="track.album">
            <img [src]="wpImages.getDefault('album')" [lazyLoad]="track.album.image" class="lazy-loaded">
            <playback-control-button [track]="track" (play)="playTrack(track, index)" (pause)="player.pause()"></playback-control-button>
        </div>
        <div class="meta">
            <div class="track-name">{{track.name}}</div>
            <div class="artist-name">{{track.artists}}</div>
        </div>
        <button class="no-style toggle-menu" (click)="showContextMenu(track, $event)"><mat-icon svgIcon="more-horiz"></mat-icon></button>
    </div>
</div>