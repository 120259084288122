/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../lazy-load.directive";
import * as i5 from "../../../../common/core/translations/translate.directive";
import * as i6 from "../../../../common/core/translations/translations.service";
import * as i7 from "../../../../common/core/config/settings.service";
import * as i8 from "./user-item.component";
import * as i9 from "../../web-player-urls.service";
import * as i10 from "../../web-player-images.service";
var styles_UserItemComponent = [i0.styles];
var RenderType_UserItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_UserItemComponent, data: {} });
export { RenderType_UserItemComponent as RenderType_UserItemComponent };
export function View_UserItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "img-container"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["class", "lazy-loaded"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(4, 4210688, null, 0, i4.LazyLoadDirective, [i1.ElementRef], { scrollTarget: [0, "scrollTarget"], imgUrl: [1, "imgUrl"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "user-name primary-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "followers secondary-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(16, 4341760, null, 0, i5.TranslateDirective, [i1.ElementRef, i6.Translations, i7.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Followers"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.user(_co.user); _ck(_v, 1, 0, currVal_2); var currVal_4 = _co.scrollContainer; var currVal_5 = _co.user.avatar; _ck(_v, 4, 0, currVal_4, currVal_5); var currVal_8 = _co.urls.user(_co.user); _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.wpImages.getDefault("artist"); _ck(_v, 3, 0, currVal_3); var currVal_6 = i1.ɵnov(_v, 10).target; var currVal_7 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_9 = _co.user["display_name"]; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.user["followers_count"]; _ck(_v, 14, 0, currVal_10); }); }
export function View_UserItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-item", [["class", "media-grid-item"]], null, null, null, View_UserItemComponent_0, RenderType_UserItemComponent)), i1.ɵdid(1, 49152, null, 0, i8.UserItemComponent, [i9.WebPlayerUrls, i7.Settings, i10.WebPlayerImagesService], null, null)], null, null); }
var UserItemComponentNgFactory = i1.ɵccf("user-item", i8.UserItemComponent, View_UserItemComponent_Host_0, { scrollContainer: "scrollContainer", user: "user" }, {}, []);
export { UserItemComponentNgFactory as UserItemComponentNgFactory };
