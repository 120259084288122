<button class="no-style panel-header" (click)="closePanel(); $event.stopPropagation()">
    <mat-icon svgIcon="arrow-back"></mat-icon>
    <span trans>Back</span>
</button>
<div class="panel-body">
    <div class="title" trans>Select</div>

    <button class="no-style new-playlist" (click)="openNewPlaylistModal()">
        <mat-icon svgIcon="playlist-add"></mat-icon>
        <span trans>New Playlist</span>
    </button>

    <div class="playlists" customScrollbar>
        <button class="playlist no-style" *ngFor="let playlist of userPlaylists.get()" (click)="addTracks(playlist)" trans>{{playlist.name}}</button>
    </div>
</div>