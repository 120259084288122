<filterable-page-header [filterQuery]="filterQuery" class="margin">
    <span trans>Popular Genres</span>
</filterable-page-header>

<ad-host slot="ads.general_top"></ad-host>

<media-grid class="genres">
    <a class="media-grid-item genre" *ngFor="let genre of filteredItems" [routerLink]="urls.genre(genre)">
        <div class="img-container">
            <img class="lazy-loaded" [src]="wpImages.getDefault('artist')" [lazyLoad]="genre.image">
        </div>
        <div class="name">{{genre.name}}</div>
    </a>
</media-grid>

<no-results-message *ngIf="!filteredItems || ! filteredItems.length">
    <span primary-text trans>No genres found.</span>
    <span secondary-text trans>Could not find any popular genres. Please try again later.</span>
</no-results-message>

<ad-host slot="ads.general_bottom" class="margin-bottom"></ad-host>
