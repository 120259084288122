import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Track } from "../../models/Track";
import { Player } from "../player/player.service";
import { Translations } from "common/core/translations/translations.service";
var RadioPageComponent = /** @class */ (function () {
    /**
     * RadioPageComponent Constructor.
     */
    function RadioPageComponent(route, player, i18n) {
        this.route = route;
        this.player = player;
        this.i18n = i18n;
    }
    RadioPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.seed = data.radio.seed;
            _this.type = _this.i18n.t(data.radio.type);
            _this.tracks = data.radio.recommendations.map(function (track) {
                return new Track(track);
            });
        });
    };
    /**
     * Get image for this radio.
     */
    RadioPageComponent.prototype.getImage = function () {
        return this.seed['image_small'] || this.seed['album'].image;
    };
    /**
     * Check if this radio is playing currently.
     */
    RadioPageComponent.prototype.playing = function () {
        return this.player.isPlaying() && this.isQueued();
    };
    /**
     * Play the radio.
     */
    RadioPageComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isQueued()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: this.tracks, queuedItemId: this.getQueueId() })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the player.
     */
    RadioPageComponent.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Get queue ID for this radio.
     */
    RadioPageComponent.prototype.getQueueId = function () {
        return 'radio.' + this.seed.id;
    };
    /**
     * Check if this radio is currently queued in player.
     */
    RadioPageComponent.prototype.isQueued = function () {
        return this.player.queue.itemIsQueued(this.getQueueId());
    };
    return RadioPageComponent;
}());
export { RadioPageComponent };
