import * as tslib_1 from "tslib";
import { UserLibrary } from "./web-player/users/user-library/user-library.service";
import { UserPlaylists } from "./web-player/playlists/user-playlists.service";
import { Bootstrapper } from "common/core/bootstrapper.service";
var BeMusicBootstrapper = /** @class */ (function (_super) {
    tslib_1.__extends(BeMusicBootstrapper, _super);
    function BeMusicBootstrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Handle specified bootstrap data.
     */
    BeMusicBootstrapper.prototype.handleData = function (encodedData) {
        var data = _super.prototype.handleData.call(this, encodedData);
        //set user library
        this.injector.get(UserLibrary).setTrackIds(data['tracks']);
        //set user playlists
        this.injector.get(UserPlaylists).set(data['playlists']);
        return data;
    };
    return BeMusicBootstrapper;
}(Bootstrapper));
export { BeMusicBootstrapper };
