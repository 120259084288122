import { Settings } from "common/core/config/settings.service";
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
var WebPlayerImagesService = /** @class */ (function () {
    function WebPlayerImagesService(settings) {
        this.settings = settings;
    }
    /**
     * Get default image url for artist or album.
     */
    WebPlayerImagesService.prototype.getDefault = function (type) {
        if (type === 'artist') {
            return this.settings.getAssetUrl('images/default/artist_small.jpg');
        }
        else if (type === 'artistBig') {
            return this.settings.getAssetUrl('images/default/artist-big.jpg');
        }
        else {
            return this.settings.getAssetUrl('images/default/album.png');
        }
    };
    /**
     * Get image for specified playlist.
     */
    WebPlayerImagesService.prototype.getPlaylistImage = function (playlist) {
        if (playlist.image)
            return playlist.image;
        if (playlist.tracks && playlist.tracks[0] && playlist.tracks[0].album)
            return playlist.tracks[0].album.image;
        return this.getDefault('album');
    };
    /**
     * Get available album image url or default one.
     */
    WebPlayerImagesService.prototype.getAlbumImage = function (album) {
        if (album && album.image)
            return album.image;
        return this.getDefault('album');
    };
    /**
     * Get image for specified track.
     */
    WebPlayerImagesService.prototype.getTrackImage = function (track) {
        if (!track || !track.album)
            return this.getDefault('album');
        return track.album.image;
    };
    WebPlayerImagesService.ngInjectableDef = i0.defineInjectable({ factory: function WebPlayerImagesService_Factory() { return new WebPlayerImagesService(i0.inject(i1.Settings)); }, token: WebPlayerImagesService, providedIn: "root" });
    return WebPlayerImagesService;
}());
export { WebPlayerImagesService };
