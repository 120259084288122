import * as tslib_1 from "tslib";
import { UploadValidation } from './upload-validation';
var AllowedExtensionsValidation = /** @class */ (function (_super) {
    tslib_1.__extends(AllowedExtensionsValidation, _super);
    function AllowedExtensionsValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.i18n.t('Only these file types are allowed: :extensions', { extensions: _this.params.extensions.join(', ') });
        return _this;
    }
    AllowedExtensionsValidation.prototype.fails = function (file) {
        return !this.params.extensions.some(function (extension) {
            return extension.toLowerCase() === file.extension.toLowerCase();
        });
    };
    return AllowedExtensionsValidation;
}(UploadValidation));
export { AllowedExtensionsValidation };
