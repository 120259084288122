import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FilterablePage } from '../../filterable-page/filterable-page';
import { ActivatedRoute } from '@angular/router';
var PopularAlbumsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PopularAlbumsComponent, _super);
    function PopularAlbumsComponent(route) {
        var _this = _super.call(this, ['name', 'artist.name']) || this;
        _this.route = route;
        return _this;
    }
    PopularAlbumsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.api.albums);
        });
    };
    return PopularAlbumsComponent;
}(FilterablePage));
export { PopularAlbumsComponent };
