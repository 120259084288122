/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./library-playlists.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../../common/core/translations/translations.service";
import * as i6 from "../../../../../common/core/config/settings.service";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./library-playlists.component";
import * as i10 from "../../../web-player-urls.service";
import * as i11 from "../../../../../common/core/ui/context-menu/context-menu.service";
import * as i12 from "../../../web-player-images.service";
var styles_LibraryPlaylistsComponent = [i0.styles];
var RenderType_LibraryPlaylistsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LibraryPlaylistsComponent, data: {} });
export { RenderType_LibraryPlaylistsComponent as RenderType_LibraryPlaylistsComponent };
function View_LibraryPlaylistsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "playlist"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 16, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "tracks-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(15, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Songs"])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["class", "no-style options-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showContextMenu(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "more-vert"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(23, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.playlist(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); var currVal_8 = "more-vert"; _ck(_v, 23, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.getImage(_v.context.$implicit); _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit["tracks_count"]; _ck(_v, 13, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 23).inline; var currVal_7 = (((i1.ɵnov(_v, 23).color !== "primary") && (i1.ɵnov(_v, 23).color !== "accent")) && (i1.ɵnov(_v, 23).color !== "warn")); _ck(_v, 22, 0, currVal_6, currVal_7); }); }
export function View_LibraryPlaylistsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "playlists"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryPlaylistsComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.playlists; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LibraryPlaylistsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "library-playlists", [["class", "user-library-page"]], null, null, null, View_LibraryPlaylistsComponent_0, RenderType_LibraryPlaylistsComponent)), i1.ɵdid(1, 114688, null, 0, i9.LibraryPlaylistsComponent, [i10.WebPlayerUrls, i6.Settings, i11.ContextMenu, i2.ActivatedRoute, i12.WebPlayerImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LibraryPlaylistsComponentNgFactory = i1.ɵccf("library-playlists", i9.LibraryPlaylistsComponent, View_LibraryPlaylistsComponent_Host_0, {}, {}, []);
export { LibraryPlaylistsComponentNgFactory as LibraryPlaylistsComponentNgFactory };
