/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading-indicator.component.ngfactory";
import * as i3 from "../loading-indicator.component";
import * as i4 from "./loading-page.component";
var styles_LoadingPageComponent = [i0.styles];
var RenderType_LoadingPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoadingPageComponent, data: {} });
export { RenderType_LoadingPageComponent as RenderType_LoadingPageComponent };
export function View_LoadingPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [["class", "overlay"]], [[40, "@visibility", 0]], null, null, i2.View_LoadingIndicatorComponent_0, i2.RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingIndicatorComponent, [], { isVisible: [0, "isVisible"] }, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isVisible; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoadingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-page", [], null, null, null, View_LoadingPageComponent_0, RenderType_LoadingPageComponent)), i1.ɵdid(1, 49152, null, 0, i4.LoadingPageComponent, [], null, null)], null, null); }
var LoadingPageComponentNgFactory = i1.ɵccf("loading-page", i4.LoadingPageComponent, View_LoadingPageComponent_Host_0, {}, {}, []);
export { LoadingPageComponentNgFactory as LoadingPageComponentNgFactory };
