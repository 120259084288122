import { EventEmitter } from '@angular/core';
import { LocalStorage } from 'common/core/services/local-storage.service';
var PlayerState = /** @class */ (function () {
    function PlayerState(storage) {
        this.storage = storage;
        this._playing = false;
        this._buffering = false;
        this._muted = false;
        this._repeating = true;
        this._repeatingOne = false;
        this._shuffling = false;
        this.onChange$ = new EventEmitter();
    }
    /**
     * Fired when playback ends (track reaches the end)
     */
    PlayerState.prototype.firePlaybackEnded = function () {
        this.onChange$.emit('PLAYBACK_ENDED');
    };
    /**
     * Fired when playback is stopped (paused and seeked to 0)
     */
    PlayerState.prototype.firePlaybackStopped = function () {
        this.onChange$.emit('PLAYBACK_STOPPED');
    };
    /**
     * Fired when playback strategy is first bootstrapped.
     */
    PlayerState.prototype.fireReadyEvent = function () {
        this.onChange$.emit('PLAYBACK_STRATEGY_READY');
    };
    Object.defineProperty(PlayerState.prototype, "playing", {
        get: function () {
            return this._playing;
        },
        set: function (value) {
            if (this._playing === value)
                return;
            this._playing = value;
            if (value && this.buffering)
                this.buffering = false;
            this.onChange$.emit('PLAYBACK_' + (value ? 'STARTED' : 'PAUSED'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerState.prototype, "buffering", {
        get: function () {
            return this._buffering;
        },
        set: function (value) {
            if (this._buffering === value)
                return;
            this._buffering = value;
            if (value && this.playing)
                this.playing = false;
            this.onChange$.emit('BUFFERING_' + (value ? 'STARTED' : 'STOPPED'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerState.prototype, "muted", {
        get: function () {
            return this._muted;
        },
        set: function (value) {
            this._muted = value;
            this.storage.set('player.muted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerState.prototype, "repeating", {
        get: function () {
            return this._repeating;
        },
        set: function (value) {
            this._repeating = value;
            this.storage.set('player.repeating', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerState.prototype, "repeatingOne", {
        get: function () {
            return this._repeatingOne;
        },
        set: function (value) {
            this._repeatingOne = value;
            if (value)
                this.repeating = false;
            this.storage.set('player.repeatingOne', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlayerState.prototype, "shuffling", {
        get: function () {
            return this._shuffling;
        },
        set: function (value) {
            this._shuffling = value;
            this.storage.set('player.shuffling', value);
        },
        enumerable: true,
        configurable: true
    });
    return PlayerState;
}());
export { PlayerState };
