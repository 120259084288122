import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FilterablePage } from "../../../filterable-page/filterable-page";
import { ActivatedRoute } from "@angular/router";
import { WebPlayerUrls } from "../../../web-player-urls.service";
var LibraryArtistsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LibraryArtistsComponent, _super);
    /**
     * LibraryArtistsComponent Constructor.
     */
    function LibraryArtistsComponent(route, urls) {
        var _this = _super.call(this, ['name']) || this;
        _this.route = route;
        _this.urls = urls;
        /**
         * Current order for library albums.
         */
        _this.order = 'Date Added';
        return _this;
    }
    LibraryArtistsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.artists);
        });
    };
    return LibraryArtistsComponent;
}(FilterablePage));
export { LibraryArtistsComponent };
