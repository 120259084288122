import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Settings } from '../common/core/config/settings.service';
import { Translations } from '../common/core/translations/translations.service';
import { ucFirst } from '../common/core/utils/uc-first';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../common/core/config/settings.service";
import * as i4 from "../common/core/translations/translations.service";
var TAG_CLASS = 'dynamic-seo-tag';
var MetaService = /** @class */ (function () {
    function MetaService(document, router, settings, i18n, route) {
        this.document = document;
        this.router = router;
        this.settings = settings;
        this.i18n = i18n;
        this.route = route;
    }
    MetaService.prototype.init = function () {
        var _this = this;
        this.activeRouteData$()
            .subscribe(function (data) {
            if (data.api && data.api.seo) {
                _this.addTags(data.api.seo);
            }
            else if (!data.willSetSeo) {
                _this.setDefaultTags(data);
            }
        });
    };
    MetaService.prototype.addTags = function (tags) {
        var _this = this;
        this.removeOldTags();
        var firstChild = this.document.head.firstChild;
        tags.forEach(function (tag) {
            var node = document.createElement(tag.nodeName);
            node.classList.add(TAG_CLASS);
            Object.keys(tag).forEach(function (key) {
                if (key === 'nodeName')
                    return;
                if (key === '_text') {
                    node.textContent = typeof tag[key] === 'string' ? tag[key] : JSON.stringify(tag[key]);
                }
                else {
                    node.setAttribute(key, tag[key]);
                }
            });
            _this.document.head.insertBefore(node, firstChild);
        });
    };
    MetaService.prototype.removeOldTags = function () {
        var tags = this.document.head.getElementsByClassName(TAG_CLASS);
        for (var i = 0; i < tags.length; i++) {
            this.document.head.removeChild(tags[i]);
        }
    };
    MetaService.prototype.activeRouteData$ = function () {
        var _this = this;
        return this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function () { return _this.route; }), map(function (route) {
            while (route.firstChild)
                route = route.firstChild;
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; }));
    };
    MetaService.prototype.setDefaultTags = function (data) {
        var title = {
            nodeName: 'title',
            _text: this.settings.get('branding.site_name'),
        };
        var defaultTitle = data.title || data.name;
        // prepend route name to site name, if available
        if (defaultTitle) {
            var name_1 = this.i18n.t(defaultTitle.replace('-', ' '));
            title._text = name_1 + ' - ' + title._text;
        }
        title._text = ucFirst(title._text);
        this.addTags([title]);
    };
    MetaService.ngInjectableDef = i0.defineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.inject(i1.DOCUMENT), i0.inject(i2.Router), i0.inject(i3.Settings), i0.inject(i4.Translations), i0.inject(i2.ActivatedRoute)); }, token: MetaService, providedIn: "root" });
    return MetaService;
}());
export { MetaService };
