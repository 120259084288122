import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { WebPlayerUrls } from "../../web-player-urls.service";
import { Player } from "../../player/player.service";
import { Playlist } from "../../../models/Playlist";
import { PlaylistService } from "../playlist.service";
import { User } from "common/core/types/models/User";
import { WebPlayerImagesService } from "../../web-player-images.service";
var PlaylistItemComponent = /** @class */ (function () {
    /**
     * PlaylistItemComponent Constructor
     */
    function PlaylistItemComponent(urls, player, playlistService, wpImages) {
        this.urls = urls;
        this.player = player;
        this.playlistService = playlistService;
        this.wpImages = wpImages;
    }
    PlaylistItemComponent.prototype.ngOnDestroy = function () {
        this.playlistService.destroy();
    };
    /**
     * Check if playlist is currently playing.
     */
    PlaylistItemComponent.prototype.playing = function () {
        this.playlistService.playing(this.playlist);
    };
    /**
     * Play all playlist's tracks.
     */
    PlaylistItemComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.player.stop();
                        this.player.state.buffering = true;
                        return [4 /*yield*/, this.playlistService.init(this.playlist.id)];
                    case 1:
                        _a.sent();
                        this.playlistService.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the playlist.
     */
    PlaylistItemComponent.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Get creator of playlist.
     */
    PlaylistItemComponent.prototype.getCreator = function () {
        return this.creator || this.playlist.editors[0];
    };
    return PlaylistItemComponent;
}());
export { PlaylistItemComponent };
