<filterable-page-header class="margin" [filterQuery]="filterQuery">
    <sorting-header [name]="order">
        <div mat-menu-item (click)="sort('added_at')" trans>Date Added</div>
        <div mat-menu-item (click)="sort('name')" trans>Name</div>
        <div mat-menu-item (click)="sort('artist.name')" trans>Artist Name</div>
    </sorting-header>
</filterable-page-header>

<ad-host slot="ads.general_top" class="margin-bottom"></ad-host>

<media-grid>
    <album-item *ngFor="let album of filteredItems" [album]="album" [contextMenu]="{item: album, type: 'album'}"></album-item>
</media-grid>

<no-results-message *ngIf="! filteredItems || ! filteredItems.length">
    <span primary-text trans>Nothing to display.</span>
    <span secondary-text trans>You have not added any albums to your library yet.</span>
</no-results-message>