<div infinite-scroll (onInfiniteScroll)="loadMore()" [infiniteScrollEnabled]="canLoadMore()">
    <filterable-page-header [filterQuery]="filterQuery" class="margin">
        <span class="genre-name">
            {{genre.name}}
            <span trans>Artists</span>
        </span>
    </filterable-page-header>

    <ad-host slot="ads.general_top"></ad-host>

    <media-grid>
        <artist-item *ngFor="let artist of filteredItems" [artist]="artist" [contextMenu]="{item: artist, type: 'artist'}" [scrollContainer]="scrollContainer"></artist-item>
    </media-grid>

    <ad-host slot="ads.general_bottom" class="margin-bottom"></ad-host>
</div>