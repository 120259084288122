/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-grid.component.scss.ngstyle";
import * as i1 from "./media-grid-item.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./media-grid.component";
var styles_MediaGridComponent = [i0.styles, i1.styles];
var RenderType_MediaGridComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_MediaGridComponent, data: {} });
export { RenderType_MediaGridComponent as RenderType_MediaGridComponent };
export function View_MediaGridComponent_0(_l) { return i2.ɵvid(0, [i2.ɵncd(null, 0)], null, null); }
export function View_MediaGridComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "media-grid", [], null, null, null, View_MediaGridComponent_0, RenderType_MediaGridComponent)), i2.ɵdid(1, 49152, null, 0, i3.MediaGridComponent, [], null, null)], null, null); }
var MediaGridComponentNgFactory = i2.ɵccf("media-grid", i3.MediaGridComponent, View_MediaGridComponent_Host_0, {}, {}, ["*"]);
export { MediaGridComponentNgFactory as MediaGridComponentNgFactory };
