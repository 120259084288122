import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { WebPlayerUrls } from '../../web-player-urls.service';
import { FilterablePage } from '../../filterable-page/filterable-page';
import { ActivatedRoute } from '@angular/router';
import { Settings } from 'common/core/config/settings.service';
import { WebPlayerImagesService } from '../../web-player-images.service';
var PopularGenresComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PopularGenresComponent, _super);
    function PopularGenresComponent(route, urls, settings, wpImages) {
        var _this = _super.call(this, ['name']) || this;
        _this.route = route;
        _this.urls = urls;
        _this.settings = settings;
        _this.wpImages = wpImages;
        return _this;
    }
    PopularGenresComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.api.genres);
        });
    };
    return PopularGenresComponent;
}(FilterablePage));
export { PopularGenresComponent };
