import * as tslib_1 from "tslib";
import { share, map } from 'rxjs/operators';
import { AppHttpClient } from "common/core/http/app-http-client.service";
import { FilterablePage } from "../../filterable-page/filterable-page";
import { Player } from "../../player/player.service";
var LibraryTracks = /** @class */ (function (_super) {
    tslib_1.__extends(LibraryTracks, _super);
    /**
     * LibraryTracks Service Constructor.
     */
    function LibraryTracks(http, player) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.player = player;
        /**
         * Ids of all tracks in user library.
         */
        _this.trackIds = [];
        /**
         * Current track order.
         */
        _this.order = 'date added';
        /**
         * Current replies infinite load page.
         */
        _this.currentPage = 1;
        /**
         * Last replies infinite load page.
         */
        _this.lastPage = 2;
        /**
         * Whether library tracks are being loaded currently.
         */
        _this.loading = false;
        /**
         * Whether at least one page of library tracks has already been fetched.
         */
        _this.alreadyFetched = false;
        _this.bindToPlaybackEndEvent();
        return _this;
    }
    /**
     * Get all currently loaded user library tracks.
     */
    LibraryTracks.prototype.get = function () {
        return this.filteredItems;
    };
    /**
     * Check if user has specified track in library.
     */
    LibraryTracks.prototype.has = function (track) {
        return this.trackIds.indexOf(track.id) > -1;
    };
    /**
     * Sort tracks using specified order.
     */
    LibraryTracks.prototype.sort = function (order) {
        var _this = this;
        this.order = order;
        this.loadTracks({ page: 1 }).subscribe(function (response) {
            _this.setPaginationResponse(response);
        });
    };
    /**
     * Add specified tracks to library.
     */
    LibraryTracks.prototype.add = function (tracks) {
        var _this = this;
        if (!Array.isArray(tracks))
            tracks = [tracks];
        var ids = tracks.map(function (track) { return track.id; });
        this.http.post('user/library/tracks/add', { ids: ids }).subscribe(function () {
            _this.prependItems(tracks);
            _this.trackIds = _this.trackIds.concat(ids);
        }, function () { });
    };
    /**
     * Remove specified tracks from library.
     */
    LibraryTracks.prototype.remove = function (tracks) {
        var _this = this;
        if (!Array.isArray(tracks))
            tracks = [tracks];
        var ids = tracks.map(function (track) { return track.id; });
        this.http.post('user/library/tracks/remove', { ids: ids }).subscribe(function () {
            _this.removeItems(tracks);
            tracks.forEach(function (track) {
                _this.trackIds.splice(_this.trackIds.findIndex(function (id) { return track.id === id; }), 1);
            });
        }, function () { });
    };
    /**
     * Check if more tracks can be loaded via infinite load.
     */
    LibraryTracks.prototype.canLoadMoreTracks = function () {
        return !this.loading && !this.filterQuery.value && this.currentPage < this.lastPage;
    };
    /**
     * Load next infinite load page.
     */
    LibraryTracks.prototype.loadMore = function () {
        var _this = this;
        this.loading = true;
        var request = this.loadTracks({ page: this.currentPage + 1 });
        request.subscribe(function (response) {
            _this.appendItems(response.data);
        });
        return request;
    };
    /**
     * Set ids of all tracks in user's library.
     */
    LibraryTracks.prototype.setTrackIds = function (ids) {
        if (!ids)
            ids = [];
        this.trackIds = ids.slice();
    };
    /**
     * Reset user library tracks service to initial state.
     */
    LibraryTracks.prototype.reset = function () {
        this.trackIds = [];
        this.order = 'date added';
        this.currentPage = 1;
        this.lastPage = 2;
        this.loading = false;
        this.alreadyFetched = false;
    };
    /**
     * Set specified tracks and sync with player queue.
     */
    LibraryTracks.prototype.setItems = function (tracks) {
        _super.prototype.setItems.call(this, tracks);
        this.player.overrideQueue({ tracks: tracks });
    };
    /**
     * append specified tracks and sync with player queue.
     */
    LibraryTracks.prototype.appendItems = function (tracks) {
        _super.prototype.appendItems.call(this, tracks);
        this.player.getQueue().append(tracks);
    };
    /**
     * Set specified filtered tracks (via search) and sync with player queue.
     */
    LibraryTracks.prototype.setFilteredItems = function (tracks) {
        _super.prototype.setFilteredItems.call(this, tracks);
        this.player.overrideQueue({ tracks: tracks });
    };
    /**
     * Filter library tracks by specified query.
     */
    LibraryTracks.prototype.filter = function (query) {
        return this.fetch({ query: query }).pipe(map(function (response) { return response.data; }));
    };
    /**
     * Load tracks based on specified params.
     */
    LibraryTracks.prototype.loadTracks = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.loading = true;
        params.order = this.order.replace(' ', '_');
        var request = this.fetch(params).pipe(share());
        request.subscribe(function (response) {
            _this.currentPage = response.current_page;
            _this.lastPage = response.last_page;
            _this.loading = false;
        }, function () { });
        return request;
    };
    /**
     * Get tracks in user's library.
     */
    LibraryTracks.prototype.fetch = function (params) {
        if (params === void 0) { params = { page: 1 }; }
        this.alreadyFetched = true;
        return this.http.get('user/library/tracks', params);
    };
    /**
     * init or reset the service using specified pagination response.
     */
    LibraryTracks.prototype.setPaginationResponse = function (response) {
        if (!response)
            return;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.setItems(response.data);
    };
    /**
     * Lazy load more tracks from user library for
     * continuous playback after current track ends.
     */
    LibraryTracks.prototype.bindToPlaybackEndEvent = function () {
        var _this = this;
        this.player.state.onChange$.subscribe(function (type) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (type !== 'PLAYBACK_ENDED')
                            return [2 /*return*/];
                        //if player is not playing user library currently, bail
                        if (!this.player.queue.itemIsQueued('library-tracks'))
                            return [2 /*return*/];
                        if (!(this.player.getQueue().isLast() && this.canLoadMoreTracks())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadMore().toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.playNext();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return LibraryTracks;
}(FilterablePage));
export { LibraryTracks };
