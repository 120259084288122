/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playing-indicator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./playing-indicator.component";
var styles_PlayingIndicatorComponent = [i0.styles];
var RenderType_PlayingIndicatorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlayingIndicatorComponent, data: {} });
export { RenderType_PlayingIndicatorComponent as RenderType_PlayingIndicatorComponent };
export function View_PlayingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "volume-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "volume-up"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PlayingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "playing-indicator", [], null, null, null, View_PlayingIndicatorComponent_0, RenderType_PlayingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i4.PlayingIndicatorComponent, [], null, null)], null, null); }
var PlayingIndicatorComponentNgFactory = i1.ɵccf("playing-indicator", i4.PlayingIndicatorComponent, View_PlayingIndicatorComponent_Host_0, {}, {}, []);
export { PlayingIndicatorComponentNgFactory as PlayingIndicatorComponentNgFactory };
