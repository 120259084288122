import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { BrowserEvents } from "common/core/services/browser-events.service";
import { WebPlayerState } from '../../web-player-state.service';
var FilterablePageHeaderComponent = /** @class */ (function () {
    function FilterablePageHeaderComponent(browserEvents, state) {
        this.browserEvents = browserEvents;
        this.state = state;
        /**
         * Active component subscriptions.
         */
        this.subscriptions = [];
    }
    Object.defineProperty(FilterablePageHeaderComponent.prototype, "isHidden", {
        get: function () {
            return this.state.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    FilterablePageHeaderComponent.prototype.ngOnInit = function () {
        this.initKeybinds();
    };
    FilterablePageHeaderComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    /**
     * Initiate volume keyboard shortcuts.
     */
    FilterablePageHeaderComponent.prototype.initKeybinds = function () {
        var _this = this;
        var sub = this.browserEvents.globalKeyDown$.subscribe(function (e) {
            //ctrl+f - focus search bar
            if (e.ctrlKey && e.keyCode === _this.browserEvents.keyCodes.letters.f) {
                _this.filterInput.nativeElement.focus();
                e.preventDefault();
            }
        });
        this.subscriptions.push(sub);
    };
    return FilterablePageHeaderComponent;
}());
export { FilterablePageHeaderComponent };
