import * as tslib_1 from "tslib";
import { PlayerState } from '../player-state.service';
import { NgZone } from '@angular/core';
import { PlayerQueue } from '../player-queue.service';
import { Search } from '../../search/search.service';
import * as Dot from 'dot-object';
import { LazyLoaderService } from 'common/core/utils/lazy-loader.service';
var SoundcloudStrategy = /** @class */ (function () {
    /**
     * Html5Strategy Constructor.
     */
    function SoundcloudStrategy(state, zone, queue, search, lazyLoader) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.search = search;
        this.lazyLoader = lazyLoader;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         */
        this.pendingVolume = null;
        /**
         * Loaded track duration in seconds.
         */
        this.duration = 0;
        /**
         * Elapsed time in seconds since the track started playing.
         */
        this.position = 0;
    }
    /**
     * Start playback.
     */
    SoundcloudStrategy.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cueTrack(this.queue.getCurrent())];
                    case 1:
                        _a.sent();
                        this.soundcloud.play();
                        this.state.playing = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause playback.
     */
    SoundcloudStrategy.prototype.pause = function () {
        this.soundcloud.pause();
        this.state.playing = false;
    };
    /**
     * Stop playback.
     */
    SoundcloudStrategy.prototype.stop = function () {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    };
    /**
     * Seek to specified time in track.
     */
    SoundcloudStrategy.prototype.seekTo = function (time) {
        this.soundcloud.seekTo(time * 1000);
    };
    /**
     * Get loaded track duration in seconds.
     */
    SoundcloudStrategy.prototype.getDuration = function () {
        return this.duration / 1000;
    };
    /**
     * Get elapsed time in seconds since the track started playing.
     */
    SoundcloudStrategy.prototype.getCurrentTime = function () {
        return this.position / 1000;
    };
    /**
     * Set soundcloud player volume.
     */
    SoundcloudStrategy.prototype.setVolume = function (number) {
        if (!this.soundcloud) {
            this.pendingVolume = number;
        }
        else {
            this.soundcloud.setVolume(number);
        }
    };
    /**
     * Mute the player.
     */
    SoundcloudStrategy.prototype.mute = function () {
        var currentVol = this.soundcloud.getVolume();
        this.pendingVolume = typeof currentVol === 'number' ? currentVol : this.pendingVolume;
        this.soundcloud.setVolume(0);
    };
    /**
     * Unmute the player.
     */
    SoundcloudStrategy.prototype.unMute = function () {
        var volume = this.pendingVolume || 50;
        this.soundcloud.setVolume(volume);
    };
    /**
     * Get track that is currently cued for playback.
     */
    SoundcloudStrategy.prototype.getCuedTrack = function () {
        return this.cuedTrack;
    };
    /**
     * Check if youtube player is ready.
     */
    SoundcloudStrategy.prototype.ready = function () {
        return this.bootstrapped;
    };
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    SoundcloudStrategy.prototype.cueTrack = function (track) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var artist, results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.cueing === track || this.cuedTrack === track)
                            return [2 /*return*/];
                        this.cueing = track;
                        this.state.buffering = true;
                        if (!!track.youtube_id) return [3 /*break*/, 2];
                        artist = Dot.pick('album.artist.name', track) || track.artists[0];
                        return [4 /*yield*/, this.search.videoId(artist, track.name).toPromise()];
                    case 1:
                        results = _a.sent();
                        track.youtube_id = results[0].id;
                        _a.label = 2;
                    case 2: return [2 /*return*/, new Promise(function (resolve) {
                            _this.bootstrap(track).then(function () {
                                _this.soundcloud.load(track.youtube_id, _this.getPlayerVars(resolve));
                                _this.cuedTrack = track;
                                _this.cueing = null;
                            });
                        })];
                }
            });
        });
    };
    /**
     * Destroy soundcloud playback strategy.
     */
    SoundcloudStrategy.prototype.destroy = function () {
        this.soundcloud && this.soundcloud.remove && this.soundcloud.remove();
        this.soundcloud = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    };
    /**
     * Bootstrap soundcloud player.
     */
    SoundcloudStrategy.prototype.bootstrap = function (track) {
        var _this = this;
        if (this.bootstrapped)
            return new Promise(function (resolve) { return resolve(); });
        return new Promise(function (resolve) {
            _this.lazyLoader.loadScript('https://w.soundcloud.com/player/api.js').then(function () {
                var iframe = document.createElement('iframe');
                iframe.onload = function () {
                    _this.soundcloud = SC.Widget(iframe);
                    _this.handlePlayerStateChangeEvents();
                    resolve();
                };
                iframe.id = 'soundcloud-iframe';
                iframe.src = 'https://w.soundcloud.com/player/?url=' + track.youtube_id + '&color=0066cc';
                document.querySelector('.soundcloud-player').appendChild(iframe);
            });
        });
    };
    /**
     * Handle soundcloud playback state change events.
     */
    SoundcloudStrategy.prototype.handlePlayerStateChangeEvents = function () {
        var _this = this;
        this.soundcloud.bind(SC.Widget.Events.PLAY, function () {
            _this.setState('playing', true);
        });
        this.soundcloud.bind(SC.Widget.Events.PAUSE, function () {
            _this.setState('playing', false);
        });
        this.soundcloud.bind(SC.Widget.Events.PLAY_PROGRESS, function (e) {
            _this.position = e.currentPosition;
        });
        this.soundcloud.bind(SC.Widget.Events.ERROR, function () {
            _this.cuedTrack = null;
            _this.setState('playing', false);
            _this.state.firePlaybackEnded();
        });
        this.soundcloud.bind(SC.Widget.Events.FINISH, function () {
            _this.state.firePlaybackEnded();
            _this.setState('playing', false);
        });
    };
    /**
     * Set specified player state.
     */
    SoundcloudStrategy.prototype.setState = function (name, value) {
        var _this = this;
        this.zone.run(function () { return _this.state[name] = value; });
    };
    /**
     * Handle soundcloud player ready event.
     */
    SoundcloudStrategy.prototype.handlePlayerReadyEvent = function (resolve) {
        var _this = this;
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        this.soundcloud.getDuration(function (duration) {
            _this.duration = duration;
        });
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    };
    /**
     * Get soundcloud player options.
     */
    SoundcloudStrategy.prototype.getPlayerVars = function (resolve) {
        var _this = this;
        return {
            buying: false,
            liking: false,
            download: false,
            sharing: false,
            show_artwork: false,
            show_comments: false,
            show_playcount: false,
            show_user: false,
            callback: function () {
                _this.handlePlayerReadyEvent(resolve);
            }
        };
    };
    return SoundcloudStrategy;
}());
export { SoundcloudStrategy };
