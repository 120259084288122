import * as tslib_1 from "tslib";
import { DefaultUrlSerializer } from '@angular/router';
var CustomUrlSerializer = /** @class */ (function (_super) {
    tslib_1.__extends(CustomUrlSerializer, _super);
    function CustomUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomUrlSerializer.prototype.parse = function (url) {
        url = url.replace(/\+/g, '%20');
        url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return _super.prototype.parse.call(this, url);
    };
    CustomUrlSerializer.prototype.serialize = function (tree) {
        return _super.prototype.serialize.call(this, tree)
            .replace(/%20/g, '+')
            .replace(/%28/g, '(')
            .replace(/%29/g, ')');
    };
    return CustomUrlSerializer;
}(DefaultUrlSerializer));
export { CustomUrlSerializer };
