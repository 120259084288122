import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ContextMenuComponent } from "../../context-menu/context-menu.component";
var ArtistContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ArtistContextMenuComponent, _super);
    /**
     * ArtistContextMenuComponent Constructor.
     */
    function ArtistContextMenuComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        return _this;
    }
    /**
     * Copy fully qualified album url to clipboard.
     */
    ArtistContextMenuComponent.prototype.copyLinkToClipboard = function () {
        _super.prototype.copyLinkToClipboard.call(this, 'artist');
    };
    /**
     * Get all current artist tracks.
     */
    ArtistContextMenuComponent.prototype.getTracks = function () {
        return [];
    };
    /**
     * Go to artist radio route.
     */
    ArtistContextMenuComponent.prototype.goToArtistRadio = function () {
        this.contextMenu.close();
        this.router.navigate(this.urls.artistRadio(this.data.item));
    };
    return ArtistContextMenuComponent;
}(ContextMenuComponent));
export { ArtistContextMenuComponent };
