import * as tslib_1 from "tslib";
import { UploadValidation } from './upload-validation';
var BlockedExtensionsValidation = /** @class */ (function (_super) {
    tslib_1.__extends(BlockedExtensionsValidation, _super);
    function BlockedExtensionsValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.i18n.t('These file types are not allowed: :extensions', { extensions: _this.params.extensions.join(', ') });
        return _this;
    }
    BlockedExtensionsValidation.prototype.fails = function (file) {
        return this.params.extensions.some(function (extension) {
            return extension === file.extension;
        });
    };
    return BlockedExtensionsValidation;
}(UploadValidation));
export { BlockedExtensionsValidation };
