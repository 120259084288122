import * as tslib_1 from "tslib";
import { UploadValidation } from './upload-validation';
var FileTypeValidation = /** @class */ (function (_super) {
    tslib_1.__extends(FileTypeValidation, _super);
    function FileTypeValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.params.types.length > 1 ?
            _this.getPluralMessage() :
            _this.getSingularMessage();
        return _this;
    }
    FileTypeValidation.prototype.fails = function (file) {
        return !this.params.types.some(function (type) {
            return type === file.mime.split('/')[0];
        });
    };
    FileTypeValidation.prototype.getSingularMessage = function () {
        return this.i18n.t('File must be a :type.', { type: this.i18n.t(this.params.types[0]) });
    };
    FileTypeValidation.prototype.getPluralMessage = function () {
        return this.i18n.t('File must be one of these types: :types.', { types: this.i18n.t(this.params.types.join(', ')) });
    };
    return FileTypeValidation;
}(UploadValidation));
export { FileTypeValidation };
